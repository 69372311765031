<template>
  <div class="dewha-container position-relative w-100" v-if="!isLoading">
    <div class="skin-detail-section">
      <div class="d-flex justify-content-center align-items-center flex-column">
        <div class="skin-type-section">
          <div class="dewha-black-text">YOUR SKIN TYPE IS</div>
          <div class="dewha-short-heading">
            {{
              `${firstLetter(results.Q1Q3)}${firstLetter(
                results.Q4Q7
              )}${firstLetter(results.Q8Q12)}${firstLetter(results.Q13Q20)}`
            }}
          </div>
          <div class="dewha-short-detail">
            {{
              `${removeParentheses(results.Q1Q3)}, ${removeParentheses(
                results.Q4Q7
              )}, ${removeParentheses(results.Q8Q12)} , ${removeParentheses(
                results.Q13Q20
              )}`
            }}
          </div>
        </div>
        <div class="model-image-dev position-relative">
          <lazy-image
            class="w-100"
            src="https://images.quizell.com/gallery/1851727263154.png"
          ></lazy-image>
        </div>
      </div>
    </div>
    <div class="position-relative skin-type-section">
      <div
        class="skin-type-boxes flex-column d-flex justify-content-center justify-content-center"
      >
        <div class="skin-center-box d-flex justify-content-center flex-wrap">
          <div class="" v-if="firstLetter(results.Q1Q3)">
            <div class="skin-type-box">
              <div class="">
                <div class="dewha-big-ch">{{ firstLetter(results.Q1Q3) }}</div>
                <div class="dewha-skin-type-title">
                  {{ removeParentheses(results.Q1Q3) }}
                </div>
              </div>
              <div class="dewha-skin-type-detail">
                {{ description[removeParentheses(results.Q1Q3)] }}
              </div>
            </div>
          </div>
          <div class="" v-if="firstLetter(results.Q4Q7)">
            <div class="skin-type-box">
              <div class="">
                <div class="dewha-big-ch">{{ firstLetter(results.Q4Q7) }}</div>
                <div class="dewha-skin-type-title">
                  {{ removeParentheses(results.Q4Q7) }}
                </div>
              </div>
              <div class="dewha-skin-type-detail">
                {{ description[removeParentheses(results.Q4Q7)] }}
              </div>
            </div>
          </div>
          <div class="" v-if="firstLetter(results.Q8Q12)">
            <div class="skin-type-box">
              <div class="">
                <div class="dewha-big-ch">{{ firstLetter(results.Q8Q12) }}</div>
                <div class="dewha-skin-type-title">
                  {{ removeParentheses(results.Q8Q12) }}
                </div>
              </div>
              <div class="dewha-skin-type-detail">
                {{ description[removeParentheses(results.Q8Q12)] }}
              </div>
            </div>
          </div>
          <div class="" v-if="firstLetter(results.Q13Q20)">
            <div class="skin-type-box">
              <div class="">
                <div class="dewha-big-ch">
                  {{ firstLetter(results.Q13Q20) }}
                </div>
                <div class="dewha-skin-type-title">
                  {{ removeParentheses(results.Q13Q20) }}
                </div>
              </div>
              <div class="dewha-skin-type-detail">
                {{ description[removeParentheses(results.Q13Q20)] }}
              </div>
            </div>
          </div>
        </div>
        <!-- Tips -->
        <div class="">
          <div
            class="dewha-skin-tips-section mt-5 pt-md-5 d-flex align-items-center flex-column"
          >
            <div class="dewha-border d-md-none mb-5"></div>
            <div class="tips-most-heading">
              Skincare Tips for
              {{
                `${firstLetter(results.Q1Q3)}${firstLetter(
                  results.Q4Q7
                )}${firstLetter(results.Q8Q12)}${firstLetter(results.Q13Q20)}`
              }}
              Skin Type
            </div>
            <div class="dewha-tips-detail mt-4 mt-md-5">
              <div
                class="tips-detail"
                v-html="
                  skinCareTips[
                    `${firstLetter(results.Q1Q3)}${firstLetter(
                      results.Q4Q7
                    )}${firstLetter(results.Q8Q12)}${firstLetter(
                      results.Q13Q20
                    )}`
                  ][0]
                "
              ></div>
            </div>
            <div class="dewha-tips-detail mt-4 mt-md-5">
              <div
                class="tips-detail"
                v-html="
                  skinCareTips[
                    `${firstLetter(results.Q1Q3)}${firstLetter(
                      results.Q4Q7
                    )}${firstLetter(results.Q8Q12)}${firstLetter(
                      results.Q13Q20
                    )}`
                  ][1]
                "
              ></div>
            </div>
            <div class="dewha-tips-detail mt-4 mt-md-5">
              <div
                class="tips-detail"
                v-html="
                  skinCareTips[
                    `${firstLetter(results.Q1Q3)}${firstLetter(
                      results.Q4Q7
                    )}${firstLetter(results.Q8Q12)}${firstLetter(
                      results.Q13Q20
                    )}`
                  ][2]
                "
              ></div>
            </div>
          </div>
        </div>
        <!-- Products -->
        <div class="dewha-product-section mt-5">
          <div class="product-recommendation-heading mb-3">
            RECOMMENDED PRODUCTS
          </div>
          <div
            class="d-flex align-items-center justify-content-center flex-wrap dewha-products"
          >
            <div
              class="dewha-product-card mb-5 mb-md-0"
              v-for="(product, index) in selectedProducts"
              :key="index"
            >
              <div class="dewha-card-body">
                <div class="dewha-card-padding">
                  <div class="dewha-product-title">{{ product.title }}</div>
                  <div class="d-flex justify-content-center my-3">
                    <div class="dewha-product-image">
                      <lazy-image
                        class="w-100"
                        style="
                          object-fit: cover;
                          transform: translate(5%, -25%) scale(1.5);
                        "
                        :src="product.image"
                      ></lazy-image>
                    </div>
                  </div>
                  <div class="mb-4 d-flex flex-column gap" v-if="ProductBlock.useBulletDescription">
                  <div
                    class="dewha-product-description  d-flex gap align-items-center"
                    :style="{color:ProductBlock.style.bulletDescriptiontitleColor+'!important'}"
                  v-for="(bullet,index) in getBulletDescription(product.bullet_description)" :key="index"
                  >
                <div class="">
                  <i class="fa" :class="ProductBlock.selectedBulletIcon"></i>
                 
                </div>
                <div>{{ bullet }}</div>
                </div>
                
                </div>
                <div v-else
                    class="dewha-product-description mb-4" v-html="product.description"></div>
              </div>
                <div class="dewha-product-price w-100">
                  ₱ {{ product.price }}
                </div>
              </div>
              <div class="d-flex justify-content-center mt-5">
                <div class="">
                  <button @click="AddToCart(product,index)"  class="dewha-product-button" v-if="ProductBlock?.useAddToCart">
                    <span v-if="currentAddToCartProductIndex==index" class="d-flex align-items-center gap">
                      <b-spinner small></b-spinner> Adding...
                    </span>
                  <span v-else>

                    {{ProductBlock?.useAddToCartText||'Add to cart'}}
                  </span>
                  </button>
                  <a target="_blank" v-else  :href="product.detail_link" class="dewha-product-button"
                    >{{ProductBlock?.buttonText||'Shop Now'}}</a
                  >
                </div>
              </div>
            </div>
          </div>
          
          <div class="tips-most-heading my-3 my-md-5" :style="{color:getHeadings.style.color+'!important'}">
             {{ getHeadings?.content||'Check your inbox for the full report and up to 45% off voucher for your first purchase.'}} 
            </div>
        </div>
      </div>
    </div>
  </div>
  <LoaderMain v-else></LoaderMain>
</template>

<script>
import axios from "axios";

import { mapGetters } from "vuex";
import LoaderMain from "../../../../components/LoaderComps/LoaderMain.vue";
export default {
  props: {
    apiToken: String,
  },
  components: {
    LoaderMain,
  },
  data() {
    return {
      pageDesign: {},
      results: {
        Q1Q3: null,
        Q4Q7: null,
        Q8Q12: null,
        Q13Q20: null,
      },
      // Ranges as data properties
      q1_q3_ranges: [
        [1, 3.99, "Dry (very)"],
        [4, 6.99, "Dry (slight)"],
        [7, 9.99, "Oily (slight)"],
        [10, 12, "Oily (very)"],
      ],
      q4_q7_ranges: [
        [4, 6.99, "Resistant (very)"],
        [7, 9.99, "Resistant (slight)"],
        [10, 12.99, "Sensitive (slight)"],
        [13, 16, "Sensitive (very)"],
      ],
      q8_q12_ranges: [
        [5, 7.99, "Pigmented (very)"],
        [8, 12.99, "Pigmented (slight)"],
        [13, 16.99, "Non-Pigmented (slight)"],
        [17, 20, "Non-Pigmented (very)"],
      ],
      q13_q20_ranges: [
        [8, 11.99, "Tight (very)"],
        [12, 15.99, "Tight (slight)"],
        [16, 23.99, "Wrinkle-Prone (slight)"],
        [24, 32, "Wrinkle-Prone (very)"],
      ],
      description: {
        Oily: `Excess sebum production, leading to a shiny complexion.`,
        Dry: `Insufficient oil production, resulting in dry, flaky skin.`,
        Resistant: `Skin that rarely reacts adversely to products, allowing for experimentation.`,
        Sensitive: `Skin that is prone to irritation and requires gentle formulations.`,
        Pigmented: "Skin with uneven tone and areas of discoloration.",
        "Non-Pigmented": `Skin with an even tone and minimal discoloration.`,
        Tight:
          "Skin that feels firm and plump, often reflecting good hydration.",
        "Wrinkle-Prone":
          "Skin that shows a tendency to develop fine lines and wrinkles.",
      },
      selectedProducts: [],
      skinCareTips: {
        DRPT: [
          `Baumann Skin Type DRPT is characterized by <b>uneven skin tone</b> and <b>dry skin</b>. This skin type rarely suffers
from inflammation, and is less vulnerable to wrinkling.`,
          `Include skincare products with <b>SPF 30+</b> to your routine. SPF helps protect your skin from wrinkles, dullness, loss of firmness, and uneven pigmentation which occur because of UV rays - making it an essential part of your skincare routine. 
`,
          `Skincare products with <b>Hyaluronic Acid</b>, <b>Ceramides</b>, or <b>Glycerin</b> would work better with your skin type.`,
        ],
        DRPNT: [
          `Baumann Skin Type DRNT is characterized by even skin tone and dry skin. This skin type is less vulnerable to wrinkles.`,
          `Include exfoliating products, moisturizers, and daily sunscreen to your skincare regimen.`,
          `Avoid foaming cleansers, as they can irritate your skin and make your skin even more dry.`,
        ],
        DRPW: [
          `Baumann Skin Type DRPW is characterized by <b>uneven skin tone</b> and <b>dry skin</b>. This skin type is susceptible to wrinkles.`,
          `Include skincare products which help lock more moisture in their skin, such as <b>topical antioxidants, moisturizers</b>, and <b>alpha hydroxy acids (AHA)</b>, to your skincare regimen`,
          `Using sunscreen with <b>SPF 30+</b> every day and <b>retinoid products</b> every night are highly recommended, as this action will help reduce visible signs of aging on your skin. DRPWs are also recommended to choose skincare products created to treat uneven skin tone. `,
        ],
        DRNW: [
          `Baumann Skin Type DRNW is characterized by even skin tone and dry skin. Since this skin type is vulnerable to wrinkles, people with DRNW skin type are recommended to include <b>topical antioxidants</b> to their daily skincare routine, and <b>retinoid products</b> to their night skincare regimen. `,
          `Resistant skin rarely experiences unwanted reactions with new products, so you can experiment more to find your ‘holy grail’ products. `,
          `Using sunscreen with <b>SPF 15+</b> every day is highly recommended, as this action will help reduce visible signs of aging on your skin. `,
        ],
        DSPT: [
          `Baumann Skin Type DSPT is characterized by <b>uneven skin tone</b> and <b>dry skin</b> type. Depending on how dry your skin is, you may experience tightness and your skin may appear dull and feel rough. This makes <b>moisturizing</b> an important step in your skincare routine.`,
          `DSPT skin often experiences skin inflammation so invest in gentle, safe for sensitive skin products.`,
          `Focus your skincare routine on 1) building your skin’s barrier by preserving natural lipids and replacing ceramides, fatty acids and cholesterol in the skin; 2) reducing inflammation; 3) preventing skin pigmentation.`,
        ],
        DSNT: [
          `Baumann Skin Type DSNT is characterized by <b>dry skin</b>, but with minimal or no wrinkles, and even <b>skin tone</b>. This type of skin often experiences skin inflammation and depending on how dry your skin is, you may experience tightness and your skin may appear dull and feel rough. This means you need to invest in excellent <b>moisturizer</b>.`,
          `Try adding food which are rich in linoleic acid and omega 3 fatty acids, such as canola oil, to your diet to plump and moisturize your dry skin. `,
          `Avoid skincare products which contain ingredients which can irritate your skin further, such as soap and sodium lauryl sulfate (SLS).`,
        ],
        DSPW: [
          `Baumann Skin Type DSPW is characterized by <b>uneven skin tone</b> and <b>an inclination to wrinkle</b>. This type of skin often experiences skin inflammation, and may experience tightness and your skin may appear dull and feel rough.`,
          `Invest in gentle skincare products which focus on treating <b>pigmentation</b> and <b>wrinkles</b>.`,
          `Avoid skincare products which contain ingredients which can irritate your skin further, such as soap and sodium lauryl sulfate (SLS).`,
        ],
        DSNW: [
          `Baumann Skin Type DSNW is characterized by <b>inflammation</b> and <b>dry skin</b> type. This type of skin is more prone to develop wrinkles, although this skin type enjoys even skin tone. 
`,
          `Include skin barrier <b>repair moisturizers</b> and <b>topical antioxidants</b> to your daily skincare routine.`,
          `Add retinoid, which helps reduce fine lines and wrinkles as well as plump out skin, to your night skincare ritual.`,
        ],
        ORPW: [
          `Baumann Skin Type ORPW is characterized by <b>uneven skin tone</b> and <b>tendency to wrinkle</b>. ORPWs are recommended to use skincare products with higher concentrations of active ingredients, with a focus on <b>aging prevention</b>.`,
          `While you may struggle with a “shiny” face, having active oil glands can contribute to thicker dermis, which makes your skin more resilient against wrinkles, compared to dry skin. This doesn’t mean you won’t have any wrinkles or fine lines, so don’t skip anti aging products.`,
          `Resistant skin rarely experiences unwanted reactions with new products, so you can experiment more to find your ‘holy grail’ products. Our skin can change due to influences of stress level, age, climate, pollution, and the products we use, so monitor the signs your skin exhibits and adapt accordingly.`,
        ],
        OSPT: [
          `Baumann Skin Type OSPT is characterized by <b>inflammation</b> and <b>uneven skin tone</b>. This type of skin is less vulnerable to wrinkles because the natural increased skin pigmentation and the antioxidants naturally found in the skin’s sebum / oil protects the skin from aging. `,
          `Cleanse your skin twice per day, every morning and night, using a  <b>gentle, water soluble facial cleanser.</b>`,
          `Add <b>gentle moisturizer</b> to your skincare routine.`,
        ],
        OSNT: [
          `Baumann Skin Type OSNT is characterized by <b>inflammation</b> and <b>oily</b> skin. Due to the antioxidant rich sebum (skin’s oil), people with OSNT skin type are less vulnerable to wrinkles. `,
          `While you may struggle with a “shiny” face, having active oil glands can contribute to thicker dermis, which makes your skin more resilient against wrinkles, compared to dry skin. This doesn’t mean you won’t have any wrinkles or fine lines, so <b>don’t skip anti aging products.</b> `,
          `Use <b>oil free, water based skin products</b>, especially for leave on products such as face <b>moisturizers</b> and <b>sunscreen</b>.`,
        ],
        OSPW: [
          `Baumann Skin Type OSPW is characterized by <b>Pigmented</b> and <b>skin inflammation</b>. Although the skin’s sebum (natural oil) provides some antioxidant protection, it is not enough to protect this skin type from its tendency to wrinkle. `,
          `Having sensitive skin doesn’t mean you can’t try new skincare products, it simply means you have to proceed with caution. Take the time to patch test new skincare products, and you’ll be fine.`,
          `Choose lightweight, water or gel based cleanser, toner, serum, and moisturizer. <b>Try including anti aging ingredients</b> such as glycolic acid, and collagen stimulating ingredients such as <b>vitamin C</b>.`,
        ],
        OSNW: [
          `Baumann Skin Type OSNW is characterized by skin <b>inflammation</b> and <b>oily skin</b>. Although the antioxidants in the skin’s natural oil help prevent aging to a certain degree, this skin type is still wrinkle prone, so include anti aging and anti inflammatory ingredients in your skincare regimen.`,
          `While you may struggle with a “shiny” face, having active oil glands can contribute to thicker dermis, which makes your skin more resilient against wrinkles, compared to dry skin. This doesn’t mean you won’t have any wrinkles or fine lines, so <b>don’t skip anti aging</b> products. `,
          `Include <b>topical antioxidant, anti inflammatory ingredients</b>, and <b>nightly retinoid -</b> which helps reduce wrinkles and fine lines - to your skincare routine.  `,
        ],
        ORPT: [
          `Baumann Skin Type ORPT is characterized by <b>uneven skin tone</b>, although this skin type is less prone to wrinkles and irritation. `,
          `Resistant skin rarely experiences unwanted reactions with new products, so you can experiment more to find your ‘holy grail’ products. Our skin can change due to influences of stress level, age, climate, pollution, and the products we use, so monitor the signs your skin exhibits and adapt accordingly. `,
          `Use skincare products with higher concentrations of active ingredients, with <b>a focus in treating uneven skin tone</b>. `,
        ],
        ORNT: [
          `Baumann Skin Type ORNT is the <b>ideal skin type</b>. Characterized by adequate or excessive amounts of sebum (skin’s oil), even skin tone, and lower vulnerability to skin aging, ORNTs may even skip daily moisturizer from their skincare routine. `,
          `Cleanse your skin daily, but be careful not to over cleanse.
`,
          `Skincare with  <b>Niacinamide, SalicylicAcid (0.5% to 2%), or Lactic Acid </b> in their ingredients are suitable for your skin type.`,
        ],
        ORNW: [
          `Baumann Skin Type ORNW is characterized by <b>oily skin</b> and <b>a tendency to wrinkle</b>, though thanks to the antioxidants rich protective sebum (skin’s oil), this skin type wrinkle less than its dry skin counterparts. `,
          `Your skin can change due to influences of stress level, age, climate, pollution, and the products we use, so monitor the signs your skin exhibits and adapt accordingly. `,
          `Use skincare products which contain <b>strong anti aging ingredients</b>, such as retinoids and alpha hydroxy acids, to reduce the appearance of wrinkles.`,
        ],
      },
      isLoading: false,
      scoreStatus: null,
      currentAddToCartProductIndex:null
    };
  },
  computed: {
    AutoGrowResultPageClients() {
      return (
       
        this.getEnvConst?.VUE_APP_AUTOGROWRESULTPAGE_QUIZID.includes(
          this.getPreviewSettings.quizId
        )
      );
    },
    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPreviewPages",
      "getSelectedOptions",
      "getPreviewSettings",
      "getSavedLeadID",
      "translateText",
      "getAnimationSetting",
      "getEnvConst",
      "getTotalPreviewPages",
      "getSliderValues",
      "getkozimaIntervalValue",
    ]),

    getSelectedOptionsFromVuex() {
      return JSON.parse(JSON.stringify(this.getSelectedOptions));
    },
    getHeadings() {
      return this.pageDesign.blocksArray.find(
        (block) => block.type == "text"
      );

    
    },
    getProductArrayIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "product"
      );

      if (index > -1) {
        return index;
      } else {
        return null;
      }
    },
    ProductBlock() {
      return this.pageDesign.blocksArray[this.getProductArrayIndex];
    },
    getResultPageValues() {
      let getStartedDesignValues = {};
      if (this.getTotalPages && this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },
    getLeadObjfromVuex() {
      return this.getSavedLeadID;
    },
    getRecommendedQuizID() {
      return this.getEnvConst?.DEWHA_RECOMMEND_QUESTION_ID;
    },
    getRecommendedQuiz() {
      const recommend = this.getTotalPreviewPages.find(
        (page) => page.id == this.getRecommendedQuizID
      );

      if (!recommend) return null;

      const option = recommend.pageDesign?.blocksArray?.find(
        (block) => block.type === "option"
      );

      return option?.options || null;
    },
  },
  created() {
    // eslint-disable-next-line no-unreachable
    this.pageDesign = this.getSelectedPage.pageDesign;
    // Calculate the score ranges based on question groups
  },
  async mounted() {
    if (this.AutoGrowResultPageClients) {
      window.parent.postMessage({ page: "ResultPage" }, "*");
    }
    await this.calculateDehwaResultCalculations();
    this.submitAnswer();
  },
  methods: {
    async AddToCart(product, index) {


      this.currentAddToCartProductIndex = index;
      let data = {
        quiz_key: this.apiToken,
        items: [
          {
            id: product.variant_id,
            quantity: 1,
          },
        ],
      };
      if (
        product.selectedVariant &&
        Object.entries(product.selectedVariant).length !== 0
      ) {
        data.items[0].id = product.selectedVariant.external_id;
      }

      try {
        await this.increaseAddToCartCount(
          product.variant_id,
          "shopify",
          "addSingleProducts"
        );
        window.parent.postMessage(JSON.stringify(data), "*");
        this.$toasted.show("The product has been added to the cart.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally{
        this.currentAddToCartProductIndex = null;
      }
      this.currentAddToCartProductIndex = null;
    },
    async increaseAddToCartCount(products, userType, AddToCartMode) {
      let incCountPayload = {
        api_token: this.apiToken,
        quizId:
          this.getLeadObjfromVuex.quiz_id || this.getPreviewSettings.quizId,
        leadId: this.getLeadObjfromVuex.id,
        quiz_analytic_id: this.getLeadObjfromVuex?.quiz_analytic_id,
      };

      if (userType == "shopify") {
        if (AddToCartMode == "addAllProducts") {
          incCountPayload["variantIds"] = products.map((x) => x.id);
        } else {
          incCountPayload["variantIds"] = [products];
        }
      } else {
        incCountPayload["productIds"] = [products];
      }

      try {
        const response = await axios.post(
          `/api/product/analytics/increase-cart-count`,
          incCountPayload
        );
        return response.status == 200;
      } catch (error) {
        if (error) throw error;
      }
    },
    getBulletDescription(bulletArray){
      return Array.isArray(bulletArray)?bulletArray:[]

    },
    openUrl(url){
      window.top.location.href = url
    },
    calculateDehwaResultCalculations() {
      const q1_q3_score = this.calculateScore(0, 3);
      const q4_q7_score = this.calculateScore(3, 7);
      const q8_q12_score = this.calculateScore(7, 12);
      const q13_q20_score = this.calculateScore(12);

      // Getting skin type results using ranges from data properties
      this.results.Q1Q3 = this.determineSkinType(
        q1_q3_score,
        this.q1_q3_ranges
      );
      this.results.Q4Q7 = this.determineSkinType(
        q4_q7_score,
        this.q4_q7_ranges
      );
      this.results.Q8Q12 = this.determineSkinType(
        q8_q12_score,
        this.q8_q12_ranges
      );
      this.results.Q13Q20 = this.determineSkinType(
        q13_q20_score,
        this.q13_q20_ranges
      );

    },
    async submitAnswer() {
      this.isLoading = true;
      const QuizID = this.getPreviewSettings.quizId;
      let recommendOption = null;
      if (this.getRecommendedQuiz) {
        recommendOption = this.getRecommendedQuiz.find(
          (option) =>
            option.value ==
            `${this.firstLetter(this.results.Q1Q3)}${this.firstLetter(
              this.results.Q4Q7
            )}${this.firstLetter(this.results.Q8Q12)}${this.firstLetter(
              this.results.Q13Q20
            )}`
        );
      }

      const questionAnswer = this.getSelectedOptions.questionAnswer;
      if (recommendOption)
        questionAnswer.push({
          questionId: this.getRecommendedQuizID,
          isMasterQuestion: 0,
          questionType: "singleAnswer",
          selectedOption: recommendOption,
        });

      try {
        let lang = this.$route.query?.lang;
        let data = {
          quizId: QuizID,
          questionAnswer: questionAnswer,
          api_token: this.apiToken,
          leadId: this.getLeadObjfromVuex?.id,
          quiz_analytic_id: this.getLeadObjfromVuex?.quiz_analytic_id,
          tag: "",
          rangeSliderInputs: this.getSliderValues,
          lang: lang,
        };
        await axios
          .post(`/api/loadProducts`, data)
          .then((response) => {
            if (response.status == 200) {
              if (response.data.data.products.length) {
                let products = response.data.data.products.map((data) => {
                  return {
                    ...data,
                    readMore: false,
                  };
                });
                this.selectedProducts = products;
              }

              this.isLoading = false;
            }
            this.saveLeadHistory();
          })
          .catch((error) => {
            if (error) {
              this.$toasted.show("Error occured", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
            }
            throw error;
          });
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      }
    },

    
    getProducts(block) {
      if (block.limitProduct) {
        let Products = [...this.products];
        if (
          block.limitProductLength &&
          block.limitProductLength < Products.length
        ) {
          let newArr = [];
          for (let i = 0; i < block.limitProductLength; i++) {
            newArr.push(Products[i]);
          }
          return newArr;
        } else {
          return Products;
        }
      } else {
        return this.products;
      }
    },
    async saveLeadHistory(tagText = "") {
      const QuizID = this.getPreviewSettings.quizId;
      try {
        let data = {
          quizId: QuizID,
          leadId: this.getSavedLeadID.id,
          // products: JSON.stringify(this.products),
          products: JSON.stringify(this.selectedProducts),
          questionAnswer: JSON.stringify(
            this.getSelectedOptions.questionAnswer
          ),
          api_token: this.apiToken,
          quiz_analytic_id: this.getLeadObjfromVuex.quiz_analytic_id,
          tag: tagText,
        };

        if (this.isTest) {
          data["isTest"] = true;
        }
        if (this.scoreStatus && this.score != "") {
          data["total_score"] = this.score;
        }
        await axios.post(`/api/saveLeadHistory`, data);
      } catch (error) {
        console.log(error)
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      }
    },
    calculateScore(start, end) {
      const slice = end
        ? this.getSelectedOptionsFromVuex.questionAnswer.slice(start, end)
        : this.getSelectedOptionsFromVuex.questionAnswer.slice(start);
      return slice.reduce(
        (acc, entry) => acc + entry.selectedOption.score_value,
        0
      );
    },
    determineSkinType(score, ranges) {
      for (let range of ranges) {
        if (range[0] <= score && score <= range[1]) {
          return range[2];
        }
      }
      return null;
    },
    removeParentheses(text) {
      return text ? text.replace(/\s*\(.*?\)\s*/g, "") : "";
    },
    firstLetter(str) {
      return str ? str[0] : "";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&display=swap");
.dewha-logo-section {
  box-shadow: 0px 4px 6.8px rgba(0, 0, 0, 0.25);
  background: white;
  border-radius: 100px;
  margin: 0px 20px 0px 20px;
}
.dewha-container {
  background-image: url(https://images.quizell.com/gallery/2221727182465.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  height: 1268px;
}
.dewha-container1 {
  padding: 0px 20px 0px 20px;
  --dewha-primary: #f14257;
  --dewha-secondary: #393939;
}
.dewha-logo {
  width: 200px;
  height: 82px;
  margin-top: 17px;
  margin-bottom: 17px;
}
.model-image-dev {
  max-width: 1087px;
}
.skin-detail-section {
  padding-top: 59px;
}
.dewha-black-text {
  color: #1e1e1e;
  text-align: center;
  font-family: "Work Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dewha-short-heading {
  color: #47a8de;
  text-align: center;
  font-family: "Work Sans";
  font-size: 66px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;
}
.dewha-short-detail {
  color: #47a8de;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25px;
  margin-bottom: 15px;
}
.model-image-info {
  border-radius: 29px;
  border: 1px solid #000;
  background: rgba(255, 255, 255, 0.76);
  color: #393939;
  text-align: center;
  font-family: "Atkinson Hyperlegible";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
  padding: 15px;
  max-width: 276px;
}
.eye-info {
  position: absolute;
  top: 78px;
  left: 1%;
}
.right-info {
  position: absolute;
  top: 117px;
  right: 38px;
}
.right-info1 {
  position: absolute;
  top: 247px;
  left: 1%;
}
.skin-type-boxes {
  position: absolute;
  width: 100%;
  top: -348px;
  background: url(https://images.quizell.com/gallery/1371727271360.png);
  background-blend-mode: screen;
}
.skin-type-box {
  border-radius: 60px;
  background: linear-gradient(180deg, #9cdbff 0%, #47a8de 100%);
  box-shadow: 0px 4px 6.8px 0px rgba(0, 0, 0, 0.1);
  padding: 22px;
  width: 175px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 15px;
}
.dewha-big-ch {
  color: #fff;
  text-align: center;
  font-family: "Atkinson Hyperlegible";
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -3.45px;
  line-height: 0.9;
}
.dewha-skin-type-title {
  color: #fff;
  text-align: center;
  font-family: Amiko;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dewha-skin-type-detail {
  color: #fff;
  text-align: center;
  font-family: Amiko;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.skin-center-box {
  gap: 15px;
  margin-top: 265px;
}
.tips-most-heading {
  color: #2e97d1;
  text-align: center;
  font-family: "Work Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.56px;
}
.dewha-border {
  content: "";
  display: block;
  width: 80%;
  border: 2px dashed #47a8de;
}
.tips-sub-heading {
  color: #47a8de;
  text-align: center;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
}
.tips-detail {
  color: #47a8de;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;

  line-height: normal;
  letter-spacing: 0.3px;
}
.dewha-tips-detail {
}
.dewha-product-section {
  border-radius: 100px 100px 0px 0px;
  background: url(https://images.quizell.com/gallery/7551727342767.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.product-recommendation-heading {
  color: #1e1e1e;
  text-align: center;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.dewha-card-body {
  border-radius: 56px;
  background: #fff;
  box-shadow: 0px 20px 32px 4px rgba(0, 0, 0, 0.15);
  min-height: 509px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.dewha-card-padding {
  padding: 83px 57px 0px 57px;
}
.dewha-product-title {
  color: #1e1e1e;
  text-align: center;
  font-family: "Work Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px; /* 108% */
  letter-spacing: 0.5px;
}
.dewha-product-card {
  max-width: 365px;
}
.dewha-product-image {
  width: 190px;
  height: 190px;
  object-fit: cover;
  overflow: hidden;
}
.dewha-product-description {
  color: #1e1e1e;

  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.64px;
  width: 250px;
  /* height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal; */
}
.dewha-product-price {
  border-radius: 0px 0px 56px 56px;
  background: #f1d7d8;
  box-shadow: 0px 20px 32px 4px rgba(0, 0, 0, 0.15);
  color: #fff;
  text-align: center;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 27px; /* 112.5% */
  letter-spacing: 0.48px;
  padding: 30px;
}
.dewha-product-button {
  border-radius: 87px;
  background: #f1d7d8;
  padding: 17px 41px;
  text-decoration: none;
  color: #393939;
  text-align: center;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 27px; /* 135% */
  letter-spacing: 0.4px;
  margin: 30px auto;
  border:none;
}
.dewha-products {
  gap: 77px;
}
@media (min-width: 768px) {
  .dewha-logo {
    width: 138px;
    height: 60px;
    margin-top: 17px;
    margin-bottom: 17px;
  }
  .dewha-logo-section {
    border-radius: 0px;
    width: 100%;
    margin: 0px;
  }
  .model-image-dev {
    width: 828px;
    max-width: 828px;
  }
  .skin-detail-section {
    padding-top: 55px;
  }
  .dewha-black-text {
    color: #1e1e1e;
    text-align: center;
    font-family: "Work Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .dewha-short-heading {
    color: #47a8de;
    text-align: center;
    font-family: "Work Sans";
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    line-height: 0.8;
  }
  .dewha-short-detail {
    color: #47a8de;
    text-align: center;
    font-family: "Work Sans";
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.25px;
  }
  .model-image-info {
    border-radius: 29px;
    border: 1px solid #000;
    background: rgba(255, 255, 255, 0.76);
    color: #393939;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.26px;
    padding: 15px;
  }
  .eye-info {
    position: absolute;
    top: 200px;
    left: 18px;
  }
  .right-info {
    position: absolute;
    top: 289px;
    right: 113px;
  }
  .right-info1 {
    position: absolute;
    top: 456px;
    left: 6%;
  }

  .skin-type-box {
    border-radius: 31px;
    width: 210px;
    padding: 30px;
  }
  .skin-center-box {
    gap: 40px;
    margin-top: 255px;
  }
  .skin-type-boxes {
    top: -400px;
  }
  .dewha-big-ch {
    font-size: 115px;
  }
  /* .dewha-skin-type-title{
        margin-bottom: 35px;
    } */
  .tips-most-heading {
    font-size: 26px;
  }
  .dewha-tips-detail {
    width: 75%;
  }
  .tips-sub-heading {
    font-size: 18px;
  }
  .tips-detail {
    font-size: 16px;
  }
  .dewha-product-section {
    padding: 75px 98px;
  }
  .product-recommendation-heading {
    font-size: 50px;
  }
}
@media (min-width: 1024px) {
  .dewha-container1 {
    padding: 0px 88px 0px 88px;
  }

  .dewha-card-image {
    width: 480px;
  }
}
</style>
